import Vue from "vue";
import { CameraLiveness } from "@zoox-framework/zoox-components";
import Camera from "../Camera/Camera.vue";

export default {
  name: "z-camera",
  components: {
    CameraLiveness,
    Camera
  },
  props: {
    timer: {
      type: Number,
      default: 3
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    autostart: {
      type: Boolean,
      default: false
    },
    repeat: {
      type: Boolean,
      default: false
    },
    enableLiveness: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      camera: null,
      shoot: false
    };
  },
  mounted() {
    const $context = this;
    Vue.$globalEvent = new Vue();
    Vue.$globalEvent.$on("onCapture", imageData => {
      if (typeof imageData === "boolean") {
        $context.$emit("capture", false);
      } else {
        $context.shoot = true;
        $context.src = imageData;
        setTimeout(() => {
          $context.shoot = false;
          $context.$emit("capture", imageData);
          if ($context.repeat) {
            $context.startWatch();
          } else {
            $context.stopWatch();
          }
        }, 1000);
      }
    });

    Vue.$globalEvent.$on("cameraError", exception => {
      switch (exception) {
        case "CAMERA_ERROR:NO_MEDIA":
          this.$toaster.warning(this.$t("GLOBAL.CAMERA_NOT_SUPPORTED"));
          Vue.$globalEvent.$emit("mediaNotSupported");
          break;
        case "CAMERA_ERROR:MEDIA_REJECTED":
        default:
          this.$toaster.warning(this.$t("GLOBAL.NO_CAMERA_WARN"));
      }
    });
    this.camera = this.$refs[this.referenceForCamera];
    if ($context.autostart) {
      $context.startWatch();
    }
  },
  beforeDestroy() {
    Vue.$globalEvent.$off("onCapture");
    Vue.$globalEvent.$off("cameraError");
  },
  computed: {
    liveness() {
      return this.enableLiveness ? true : false;
    },
    referenceForCamera() {
      return this.enableLiveness ? "camLiveness" : "cam";
    },
    cameraComponent() {
      return this.enableLiveness ? "CameraLiveness" : "Camera";
    }
  },
  methods: {
    startWatch() {
      this.camera.startWatch(this.timer);
    },
    stopWatch() {
      this.camera.stopWatch(this.timer);
    }
  }
};
