// import zCamera from "../../z-components/zCamera";
import zCamera from "@/components/zCamera";

export default {
  name: "zPayCameraModal",
  components: {
    zCamera
  },
  props: {
    showMessage: {
      type: Boolean,
      default: false
    },
    iconButton: {
      type: Boolean,
      default: true
    },
    backButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      timer: 3,
      isMobile: false,
      camera: null,
      personId: "",
      personName: ""
    };
  },
  mounted() {
    this.getCamera();
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined")
      return window.removeEventListener("resize", this.onResize, {
        passive: true
      });
  },
  methods: {
    getCamera() {
      this.camera = this.$refs.camera;
    },
    takePhoto() {
      this.camera.startWatch();
    },
    capture(image) {
      this.$emit("capture", image);
    },
    cameraError() {
      this.stopCamera();
    },
    stopCamera() {
      this.$emit("stop");
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    }
  }
};
