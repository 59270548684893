export default {
  name: "zPayConfirmationModal",
  props: {
    title: {
      type: String
    },
    checkIcon: {
      type: Boolean,
      default: true
    }
  }
};
