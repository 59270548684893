import Vue from "vue";

export default {
  name: "Camera",
  props: {
    isHidden: {
      default: true
    },
    watching: {
      default: false
    },
    onCapture: {
      default: undefined,
      type: Function
    },
    timer: {
      default: -1
    }
  },
  data() {
    return {
      WATCH_TIME: 10000,
      video: null,
      canvas: null,
      divTimmer: null,
      imageData: "",
      intervalInstace: null,
      timerCount: -1,
      currentStream: null,
      repeat: false
    };
  },
  mounted() {
    this.video = this.$refs["videoElement"];
    this.canvas = this.$refs["canvas"];

    this.initCamera({
      video: { facingMode: "user" },
      audio: false,
      width: { min: 1280 },
      height: { min: 720 }
    });
  },
  beforeDestroy() {
    this.stopWatch();
    this.stopCamera();
  },
  methods: {
    appendStream: function(stream) {
      if (!navigator.allMediaStreams) {
        navigator.allMediaStreams = [];
      }
      navigator.allMediaStreams.push(stream);
    },
    watch: function(time = this.WATCH_TIME) {
      this.timerCount = time;
      this.startTimer();
    },
    startWatch: function(timer, repeat) {
      this.timerCount = timer || this.timer;
      this.repeat = repeat;
      this.startTimer();
    },
    stopWatch: function() {
      this.repeat = false;
      this.timerCount = -1;

      //(*) IPC: tive que fazer assim pq o VUEJS não se dá bem com o clear interval
      for (let i = 0; i < 1000; i += 1) {
        clearInterval(i);
      }

      clearInterval(this.intervalInstace);
      this.intervalInstace = null;
    },
    startTimer: function() {
      this.intervalInstace = setInterval(
        () => {
          this.timerCount = this.timerCount > 0 ? this.timerCount - 1 : 0;

          if (this.timerCount <= 0) {
            if (this.repeat) {
              this.timerCount = this.timer;
            } else {
              this.stopWatch();
            }

            return this.getPhoto();
          }
        },
        this.timerCount > 0 ? 1000 : 0
      );
    },
    initCamera: function(config) {
      this.getUserMedia(config)
        .then(stream => {
          this.appendStream(stream);
          this.currentStream = stream;
          this.video.srcObject = stream;
        })
        .catch(ex => {
          this.$emit("cameraError", ex);
          Vue.$globalEvent.$emit("cameraError", ex);
        });
    },
    getUserMedia: function(config) {
      return new Promise((resolve, reject) => {
        try {
          navigator.getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia;

          navigator.mediaDevices
            .getUserMedia(config)
            .then(stream => {
              if (!stream) {
                throw "NO STREAM";
              }
              this.appendStream(stream);
              resolve(stream);
            })
            .catch(ex => {
              reject(
                ex && ex.name
                  ? `CAMERA_ERROR:${ex.name}`
                  : "CAMERA_ERROR:MEDIA_REJECTED"
              );
            });
        } catch (ex) {
          reject("CAMERA_ERROR:NO_MEDIA");
        }
      });
    },
    getPhoto: function() {
      this.video.pause();
      try {
        const canvasEl = this.canvas;

        canvasEl.width = this.video.clientWidth;
        canvasEl.height = this.video.clientHeight;

        const backcontext = canvasEl.getContext("2d");

        backcontext.drawImage(
          this.video,
          0,
          0,
          canvasEl.width,
          canvasEl.height
        );

        this.imageData = canvasEl.toDataURL("image/jpeg", 1.0);

        Vue.$globalEvent.$emit("onCapture", this.imageData);

        return this.imageData;
      } finally {
        this.video.play();
        this.imageData = null;
      }
    },
    stopCamera: function() {
      if (navigator.allMediaStreams && navigator.allMediaStreams.length > 0) {
        navigator.allMediaStreams.forEach(stream => {
          stream.getTracks().forEach(track => {
            track.stop();
          });
          navigator.allMediaStreams.splice(
            navigator.allMediaStreams.indexOf(stream),
            1
          );
        });
      }
    }
  }
};
