import PersonService from "@/services/PersonService";
import CompanyService from "@/services/CompanyService";
import PaymentService from "@/services/PaymentService";
import PaymentHeader from "@/components/PaymentHeader/PaymentHeader.vue";
import OrderService from "@/services/OrderService";
import zPayConfirmationModal from "@/components/zPayConfirmationModal";
import zPayTermsModal from "@/components/zPayTermsModal/zPayTermsModal.vue";
import zPayCameraModal from "@/components/zPayCameraModal";
import { ApiClient } from "@zoox-framework/zoox-infra";
import {
  validationMixin,
  DocumentTypeService
} from "@zoox-framework/smck-service";
import { FormMixin, zFlagNumber } from "@zoox-framework/zoox-components";
import Vue from "vue";

export default {
  name: "RegisterClient",
  components: {
    "z-flag-number": zFlagNumber,
    zPayCameraModal,
    PaymentHeader,
    zPayConfirmationModal,
    zPayTermsModal
  },
  mixins: [validationMixin, FormMixin],
  data() {
    return {
      personService: new PersonService(),
      companyService: new CompanyService(),
      paymentService: new PaymentService(),
      orderService: new OrderService(),
      apiClient: new ApiClient(),
      divKey: false,
      strongPasswordModal: false,
      companyName: "",
      person: {
        name: "",
        email: "",
        ddiMobile: null,
        dddMobile: null,
        phoneNumberMobile: "",
        mobileCountryCode: "",
        mobileCityCode: "",
        mobilePhoneNumber: ""
      },
      companyParams: null,
      progressBarColor: "#239465",
      passwordStrengthText: this.$t(
        "REGISTRATION.PASSWORD_STRENGTH.WEAK_PASSWORD"
      ),
      passwordCheck: 0,
      bufferValue: 100,
      camera: null,
      openCamera: false,
      showPassword: false,
      showPassword2: false,
      confirmPassword: "",
      step: 0,
      required: [v => !!v || this.$t("FORMERROR.REQUIRED")],
      requiredDocuments: {
        cpf: true,
        passport: true
      },
      testPasswordMinCharacter: [
        v =>
          (v && v.length >= 8) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.EIGHT_CHARACTERS")
      ],
      testPasswordMinNumber: [
        v =>
          (v && v.match(/[0-9]{1,}/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.NUMBER")
      ],
      testPasswordUppercase: [
        v =>
          (v && v.match(/[A-Z]{1,}/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.UPPER_CASE")
      ],
      testPasswordSpecialCharacter: [
        v =>
          (v && v.match(/[!@#$&*]/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.SPECIAL_CHARACTER")
      ],
      testPasswordLowcase: [
        v =>
          (v && v.match(/[a-z]{1,}/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.LOWER_CASE")
      ],
      debits: [],
      noCharges: false,
      openPersonExistsModal: false,
      termsOfUse: false,
      privacyPolicy: false,
      termModal: false,
      companyId: null,
      termType: null
    };
  },
  async created() {
    this.documentTypes = new DocumentTypeService(Vue).getAll(
      this.requiredDocuments
    );
    if (
      this.person.mobileCityCode == null ||
      this.person.mobilePhoneNumber == null
    ) {
      this.person.mobileCityCode = "";
      this.person.mobilePhoneNumber = "";
    }
    var charge;
    this.chargeToken = this.paymentService.getChargeToken();
    const orderService = new OrderService();
    const token =
      (await this.paymentService.getChargeToken()) ||
      (await this.paymentService.getChargeToken(
        await this.paymentService.setChargeToken(this.$route.query.chargeToken)
      ));
    await orderService
      .getChargeByToken(token)
      .then(resp => {
        charge = resp;
      })
      .catch(ex => {
        switch (ex.status) {
          case 404:
            this.noCharges = true;
            charge = null;
            break;
          case 408:
            this.expiredCharge = true;
            break;
          default:
            this.noCharges = true;
            charge = null;
            break;
        }
      });
    if (charge) {
      const orderId = charge.order.id;
      this.order = await orderService.getOrderById(orderId);
    }
    if (token) {
      if (this.order != null) {
        this.order.orderId = this.order.id || this.order._id;
        this.currency = this.order.currency || this.currency;
        this.charges = await orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
      }
      if (this.charges.length > 0) {
        let itemsIds = [];
        this.charges.forEach(charge => {
          charge.items.forEach(function(item) {
            itemsIds.push(item.id);
          });
        });
        const items = await orderService.getBulkItems(itemsIds);
        items.forEach(item => {
          const percentLeft = item.percentLeft || 100;
          const itemValue = (item.amount * item.quantity * percentLeft) / 100;
          this.debits.push({
            name: item.description,
            price: itemValue
          });
        });
      } else {
        this.noCharges = true;
        this.step = 4;
      }
    } else {
      this.noCharges = true;
      this.step = 4;
    }
  },
  async mounted() {
    const company = this.companyService.getCurrentCompany();
    this.companyParams = await this.companyService.getFnrhFormSettings(
      company.id
    );
    this.person.companyId = company.id;
    this.companyName = company.name;
    const token = this.paymentService.getChargeToken();
    const getCountryCode = param => param.slice(0, 2);
    const getCityCode = param => param.slice(2, param.length).slice(0, 2);
    const getMobilePhone = param => param.slice(4, param.length);

    if (token && token != "null") {
      try {
        const ret = await this.paymentService.getCustomerByToken(
          token,
          company.id
        );
        if (ret && ret.customer) {
          const {
            customer: { name, email, phone }
          } = ret;
          let personName = name;
          let lastSpaceIndex = personName.lastIndexOf(" ");
          this.person.name = personName.substring(0, lastSpaceIndex);
          this.person.lastName = personName.substring(
            lastSpaceIndex,
            personName.length
          );
          this.person.email = email;
          if (phone && phone.length > 8) {
            this.person.phone = phone;
            this.person.mobileCountryCode = this.person.countryId = getCountryCode(
              phone
            );
            this.person.mobileCityCode = getCityCode(phone);
            this.person.mobilePhoneNumber = getMobilePhone(phone);
            if (this.person.password) {
              delete this.person.password;
            }
          }
        }
      } catch (error) {
        this.$toaster.error(error.message);
      }
    }
    await this.getGeolocation();
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.person.password === this.confirmPassword ||
        this.$t("PAY_LOGIN.MATCH_PASSWORD");
    },
    documentTypes: function() {
      return new DocumentTypeService(Vue).getAll(this.requiredDocuments);
    }
  },
  methods: {
    agreeOrDisagree(option) {
      if (option.termType === 1) {
        this.termsOfUse = option.agree;
        this.termModal = false;
      } else if (option.termType === 2) {
        this.privacyPolicy = option.agree;
        this.termModal = false;
      }
    },
    async openTermsModal(type) {
      this.termType = type;
      this.companyId = await this.companyService.getCurrentCompanyId();
      this.termModal = true;
    },
    normalizeCardEmail() {
      this.person.email = this.person.email.replace(/\s+/g, "").toLowerCase();
    },
    async checkIfPersonAlreadyExists() {
      const exists = await this.apiClient.post(`person/check-person-exists`, {
        documentTypeId: this.person.documentTypeId,
        documentNumber: this.person.documentNumber,
        email: this.person.email
      });
      if (exists === true) {
        this.openPersonExistsModal = true;
      } else {
        this.checkForm(0);
        this.openPersonExistsModal = false;
      }
    },
    onCameraError() {
      this.stopCamera();
    },
    stopCamera() {
      this.openCamera = false;
    },
    async onCapture(image) {
      this.person.photoId = image;
      await this.register();
      this.openCamera = false;
    },
    checkForm(num) {
      if (this.step >= 0 && this.step <= 3) {
        if (num == 0) {
          if (this.$refs.registerClient.validate()) {
            this.divKey = !this.divKey;
            this.step++;
          }
        } else {
          if (this.$refs.registerClientPassword.validate()) {
            this.divKey = !this.divKey;
            this.step++;
          }
        }
      }
    },
    checkString(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/\d/.test(this.person.password)) {
        this.checkPasswordStrength();
        return true;
      }
      if (
        /^[A-Za-z]+$/.test(char) ||
        char == "!" ||
        char == "@" ||
        char == "?" ||
        char == "#" ||
        char == "*" ||
        char == " "
      ) {
        this.checkPasswordStrength();
        return true;
      }
      // Match with regex // If not match, don't add to input text
    },
    checkPasswordStrength() {
      if (this.person.password && this.person.password.length >= 8) {
        this.person.password.match(/.{8,}/) ? (this.passwordCheck += 2) : false;
        this.person.password.match(/[A-Z]{1,}/)
          ? (this.passwordCheck += 2)
          : false;
        this.person.password.match(/[0-9]{1,}/)
          ? (this.passwordCheck += 2)
          : false;
        this.person.password.match(/[!@#$&*]/)
          ? (this.passwordCheck += 2)
          : false;
        this.person.password.match(/[a-z]{1,}/)
          ? (this.passwordCheck += 2)
          : false;
      } else {
        this.passwordCheck = 0;
        this.passwordStrengthText =
          "A senha precisa ter no minimo 8 caracteres";
      }
      if (this.passwordCheck < 20) {
        this.passwordStrengthText = this.$t(
          "REGISTRATION.PASSWORD_STRENGTH.WEAK_PASSWORD"
        );
        this.progressBarColor = "#ff0000";
      } else if (this.passwordCheck >= 10 && this.passwordCheck < 30) {
        this.passwordStrengthText = this.$t(
          "REGISTRATION.PASSWORD_STRENGTH.MEDIUM_PASSWORD"
        );
        this.progressBarColor = "#ffff38";
      } else {
        this.passwordStrengthText = this.$t(
          "REGISTRATION.PASSWORD_STRENGTH.STRONG_PASSWORD"
        );
        this.progressBarColor = "#239465";
      }
    },
    getDocumentMask: function(documentType) {
      switch (documentType) {
        case 1: // CPF
          return "###.###.###-##";
        case 5: // RUT
          return "#######-N";
        default:
          return "";
      }
    },
    getDocumentLength: function(documentType) {
      switch (documentType) {
        case 4: // Passport
          return 12;
        default:
          return 20;
      }
    },
    getDocumentValidation: function(documentType, value) {
      let isValid = true;

      isValid = isValid && this.rules.required(value);

      switch (documentType) {
        case 1: // CPF
          isValid = isValid && this.rules.cpf(value);
          break;
        case 5: // RUT
          isValid = isValid && this.rules.rut(value);
          break;
        default:
          break;
      }

      return isValid;
    },
    numberInputMobile(val) {
      const lastIndexDdd = this.getLastIndexDdd(this.person.mobilePhoneMask);
      this.person.mobilePhoneNumber = val ? val.substring(lastIndexDdd) : "";
      this.person.mobileCityCode = val ? val.substring(0, lastIndexDdd) : "";
      this.person.mobileCityCode = val ? val.substring(0, lastIndexDdd) : "";
    },
    ddiInputMobile(val) {
      this.person.mobileCountryCode = val.phoneDial;
      this.person.mobileCountryCode = val.phoneDial;
      this.person.mobilePhoneMask = val.mobilePhoneMask;
      this.person.mobilePhoneCountryCode = val.code;
    },
    onMobileMaskSelect(mask) {
      this.person.mobilePhoneMask = mask;
    },
    getGeolocation() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(({ coords }) =>
            resolve(coords)
          );
        } else {
          reject("Geolocation services are not supported by your browser");
        }
      });
    },
    register() {
      this.person.companyId = this.companyService.getCurrentCompany().id;
      this.person.phoneNumberMobile =
        this.person.mobileCityCode + this.person.mobilePhoneNumber;
      this.personService
        .register(this.person)
        .then(async resp => {
          if (resp && resp.id) {
            this.$toaster.success(this.$t("SMS_MESSAGE.SUCCESS_MESSAGE"));
            const currentToken = this.paymentService.getChargeToken();
            if (currentToken && currentToken != "null") {
              this.$router.push(`/auth?chargeToken=${currentToken}`);
            } else {
              this.$router.push("/auth");
            }
          }
        })
        .catch(err => {
          if (err.status === 409) {
            this.$toaster.error(
              this.$t("REGISTRATION.PERSON_ALREADY_EXISTING")
            );
            this.person.password = "";
            this.person.confirmPassword = "";
            this.person.email = "";
            this.person.documentNumber = null;
            this.person.documentTypeId = null;
            const currentToken = this.paymentService.getChargeToken();
            if (currentToken) {
              this.$router.push(`/auth?chargeToken=${currentToken}`);
            } else {
              this.$router.push("/auth");
            }
            this.step = 0;
          }
        });
    },
    async backToAuth() {
      const token = await this.paymentService.getChargeToken();

      if (token) {
        this.$router.push(`/auth?chargeToken=${token}`);
      } else {
        this.$router.push("/auth");
      }
    },
    getPersonToRegister(person) {
      person.mobileCityCode = person.dddMobile;
      delete person.ddiMobile;
      delete person.dddMobile;
      delete person.phoneNumberMobile;
      return person;
    },
    getLastIndexDdd(mask) {
      /* eslint-disable no-useless-escape */
      mask = mask || "";
      const maskDdd = mask
        ? mask
            .replace(/[\(|\)]/g, "")
            .replace(/[-|\.|\/]/g, " ")
            .split(" ")[0]
        : "";
      if (mask.length === maskDdd.length) {
        return 0;
      }
      return maskDdd.length >= 4 ? maskDdd.length - 2 : maskDdd.length;
    }
  }
};
